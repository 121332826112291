body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  vertical-align: text-bottom !important;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 260px !important;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: #fff !important;
  text-decoration: none;
}

.card {
  background-color: transparent;
  border: none;
}

.card-header {
  padding: 0;
  padding-bottom: 15px;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-body {
  padding: 15px 0px;
}

.nav-tabs {
  border: none;
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
  border-radius: 3px;
  width: 198px;
  display: flex;
  text-align: center;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.nav-link.active {
  color: #2f62aa !important;
  font-weight: bold;
}

.nav-link {
  color: #afb9c5 !important;
}

.nav-tabs .nav-link > a {
  text-decoration: none;
  color: #afb9c5 !important;
  font-weight: 500 !important;
  font-size: 18px;
}

.nav-tabs .nav-link:hover {
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #dce9fc;
  border: none;
}

.landingLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7fc;
  border: 1px solid #d2d7de;
  border-radius: 10px;
  padding: 8px 15px;
  margin-bottom: 20px;
}
.fileCopyImg {
  cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto&display=swap');
.container-resource {
  background-color: #F8F8F8;
  margin-left: 60px;
  height: 100vh;
  display: flex;
}

.container-resourceLoading {
  background-color: #F8F8F8;
  margin-left: 60px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-resourceForm {
  // background-color: red;
  width: 60%;
  padding: 40px 30px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0px;
    margin-bottom: 30px;
  }
}

.rowLimit {
  width: 100%;
}
.main-btn-align {
  margin-top: 0px;
}
.resource-field {
  margin-bottom: 20px;
  label {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0px;
    color: #14142B;
  }
  .resource-link {
    font-weight: 700;
  }
}

.container-resourceType {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.resource-type {
  height: 29px;
  background-color: #EFF0F6;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 3px;
  p {
    font-family: 'Inter', sans-serif;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #979797;

  }

  &.active {
    background-color: #DCE9FC;
    p {
      color: #2C5999;
    }
  }
  &:hover {
    background-color: #DCE9FC;
    cursor: pointer;
  }
}

.resource-own {
  display: flex;
  align-items: center;
  label {
    margin-right: 30px;
  }
  .resource-ownOption {
    background: #C5CFD6;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    cursor: pointer;
    &.active {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2C5999;
      p {
        color: white;
        font-size: 18px;
      }

    }
  }
}

.resource-ownEach {
  display: flex;
  align-items: center;
  margin-right: 30px;
  .resource-ownTxt {
    margin-left: 10px;
    color: #2F62AACC;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
  }
}

.resource-containerDescription {
  display: flex;
  p {
    margin-left: 10px;
    font-style: italic;
    color: #616161;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0px;
  }
}

/* MENU */
.container-resourceMenu {
  // background-color: blue;
  width: 40%;
  // padding: 100px;
  display: flex;
  justify-content: center;
  background-color: white;
}
.container-resourceMenu-main {
  margin-top: 100px;
  width: 70%;
}
.resource-part {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0px;

}
.resource-partMsg {
  font-family: 'Inter', sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
}

.resource-section {
  margin-top: 30px;
  margin-bottom: 40px;
  .resource-sectionPart {
    height: 50px;
    max-width: 288px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-radius: 6px;
    p {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
    }

    &.active {
      background-color: #DDE3E9;
      p {
        font-weight: 700;
      }
    }
  }
}

.resource-btnNext {
  width: 100%;
  background-color: #7A4199;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  color: #F7F7FC;
  border: none;
  height: 50px;
  border-radius: 10px;
}

.resource-btnDraft {
  margin-top: 20px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  color: #7A4199;
  border: none;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #7A4199;
  background-color: white;
}

/* PART 2 */

.planning-theme {
  background-color: #F5CECE;
  color: #E16B67;
}
.team-theme {
  background-color: #c2c9f0;
  color: #5C6CAEB2;
}
.marketing-theme {
  background-color: #faddb9;
  color: #EFA54D;
}
.admi-theme {
  background-color: #ECD0DD;
  color: #DD74A4;
}
.tech-theme {
  background-color: #d3b7d6;
  color: #A77BAD;
}

.resource-subTitle {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0px;
  text-align: left;
}

.resource-nivel {
  display: flex;
  p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;
  }

  label {
    margin-left: 5px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;

  }
}

.container-resourceCategories {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.resource-category {
  height: 29px;
  background-color: #EFF0F6;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 3px;
  
  font-family: 'Inter', sans-serif;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #979797;

  border: none;
  

  &.planning {
    &.active {
      @extend .planning-theme;
    }
    &:hover {
      @extend .planning-theme;
    }
  }
  &.team {
    &.active {
      @extend .team-theme;
    }
    &:hover {
      @extend .team-theme;
    }
  }
  &.marketing {
    &.active {
      @extend .marketing-theme;
    }
    &:hover {
      @extend .marketing-theme;
    }
  }
  &.admi {
    &.active {
      @extend .admi-theme;
    }
    &:hover {
      @extend .admi-theme;
    }
  }
  &.tech {
    &.active {
      @extend .tech-theme;
    }
    &:hover {
      @extend .tech-theme;
    }
  }
}
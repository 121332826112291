.container-viewTactic {
  // margin-left: 20px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  width: 1400px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;

    // margin-left: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.container-viewTacticCenter {
  display: flex;
  height: 100vh;
  margin-left: 64px;
  // @media only screen and (max-width: 900px) {
  //   margin-left: 0px;
  // }
  // justify-content: center;
}

.container-tacticInfo {
  width: 50%;
  background-color: #F8F8F8;
  padding: 20px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1000px) {
    width: 50%;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-left: 0px;
  }
}

.container-tacticCenter {

  width: 80%;

  @media only screen and (min-width: 901px) {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

}

.container-tacticStep {
  width: 50%;
  padding: 30px 50px;

  @media only screen and (max-width: 1000px) {
    width: 50%;
  }

  @media only screen and (min-width: 901px) {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

}

.container-tacticBackground {
  background-size: cover;
  width: 100%;
  // height: 180px;
  // border-radius: 6px;
  // border-top: 6px;
  border-radius: 6px 6px 0px 0px;
  ;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 28px;
    font-weight: bold;
    color: white;
    margin-top: 5px;

    @media only screen and (max-width: 500px) {
      font-size: 24px;
    }
  }

  .progressBar {
    margin-top: 10px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
  }

  .container-arrowLeft {
    width: 36px;
    height: 36px;
    border: 1px solid white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    align-self: flex-end;

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  .title-tactic {
    cursor: pointer;
  }
}

.container-share {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 158px;
  height: 40px;
  border: 1px solid black;
  border-radius: 6px;
  cursor: pointer;

  strong {
    margin-left: 5px;
  }
}

.container-categoryInfo {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.container-categoryInfo-public {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
  margin-bottom: 80px;
  flex-wrap: wrap;
}

.container-categoryAndSub-public {
  display: flex;
  align-items: center;
}

.tacticCategory {
  background-color: #ECD0DD;
  height: 40px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  p {
    color: #DD74A4;
    font-weight: bold;
  }
}

.subCategory {
  height: 31px;
  border-radius: 30px;
  background-color: #DDE3E9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 20px;

  p {
    font-size: 13px;
    color: black;
  }
}

.container-tacticDescription {
  margin-top: 15px;
}

.container-tacticAuthor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.tactic-author {
  display: flex;
  align-items: center;

  img {
    height: 45px;
    width: 45px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }
}

.container-tacticStars {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #F1C33D;
    margin-right: 5px;
    font-weight: bold;
  }

  img {
    margin-left: 3px;
  }
}

.container-tacticResultsAndSkills {
  display: flex;
  flex-direction: column;

}

.container-tacticResult {
  display: flex;
  justify-content: space-between;
}

.container-tacticExpectedResult {

  justify-content: space-between;

  width: 226px;

  span {
    margin-top: 10px;
    display: inline-block;
  }
}

.container-tacticImplemented {
  div {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  strong {
    margin-left: 7px;
  }
}

.container-tacticSkills {
  margin-top: 30px;
}

.container-listSkills {
  display: flex;
  // justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
}

.container-skill {
  background-color: #DDE3E9;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  margin-top: 5px;
  margin-right: 10px;
}

.container-cardStep {
  background-color: white;
  border-radius: 15px;
  margin-top: 20px;

  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
  padding: 20px;
}

.container-stepInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.container-stepStatus {
  display: flex;
  align-items: center;
}

.title-step {
  color: #7A4199;
  font-weight: bold;
}

.name-step {
  color: black;
  font-weight: bold;
  margin-left: 5px;

}

.container-stepComplete {
  background-color: #7A4199;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 21px;
  width: 21px;
  margin-right: 10px;

  img {
    width: 10.5px;
    height: 7.88px;
  }
}

.container-stepNotComplete {
  border: 1px solid #7A4199;
  ;
  border-radius: 100%;
  height: 21px;
  width: 21px;
  margin-right: 10px;
}

.container-tacticTimeAndTool {
  display: flex;
  justify-content: space-around;

  div {
    display: flex;

    p {
      margin-left: 5px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.tactic-btnComplete {
  background-color: #7A4199;
  border-radius: 5px;
  height: 45px;
  color: white;
  border: none;
  width: 100%;
  margin-top: 24px;
}

.tactic-separator {
  visibility: hidden;
}

.container-stepProgress {
  background-color: #F18A41;
  width: 139px;
  min-width: 50px;
  height: 22px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: white;
  }

  @media only screen and (max-width: 1453px) {
    order: 1;
    margin-top: 10px;
  }
}

.img-step {
  @media only screen and (max-width: 1060px) {
    order: 2;
  }
}

/* steps */

.tactic-stepTitle {
  color: #7A4199;
  font-size: 32px;
}

.tactic-stepName {
  margin-left: 10px;
  font-size: 32px;
}

.container-infoStepTactic {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.container-stepEachInfo {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 45px;
  border: 1px solid black;
  border-radius: 6px;
  margin-right: 12px;

  p {
    margin-left: 5px;
  }
}

.container-tacticToolFrame {
  margin: 30px 0px;
}

.tactic-setpDescription {
  margin-top: 30px;
  color: black;
}

.tactic-StepBtnComplete {
  background-color: #7A4199;
  border: none;
  color: white;
  border-radius: 5px;
  width: 316px;
}

.tactic-stepAncla {
  color: #212529;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #212529;

  }
}

.tactic-objective {
  background-color: #DDE3E9;
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 30px;

  .title {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
}

.title-tools {
  // font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  margin-bottom: 15px;
}

.container-tool {
  border-radius: 6px;
  width: 100%;
  border: 1px solid black;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.container-sectionToolFirst {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 19px;
    height: 19px;
    margin-right: 12px;
  }

  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;

  }
}

.container-sectionToolSecond {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 19px;
    height: 19px;
    margin-left: 12px;
  }

  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0px;

  }
}

.container-infoExpert {
  background-color: white;
  width: 100%;
  // height: 64px;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  align-items: center;
  padding: 15px;

  // justify-content: space-around;
  img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }

  .name-expert {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    color: color;
    margin-left: 10px;
  }

  .perfil-expert {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: black;
    margin-left: 5px;

  }
}

.container-sectionContentComments {
  width: 100%;
  display: flex;
  // justify-content: space-around;
  height: 45px;
  border-radius: 15px;
  background-color: white;
  margin-top: 20px;
  align-items: center;
  border: 1px solid #D2D7DE;
  // padding: 5px;
}

.container-content {
  display: flex;
  align-items: center;
  // background-color: red;
  width: 50%;
  justify-content: center;
  border-right: 1px solid #D2D7DE;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    color: #111111;
  }

  cursor: pointer;

  &:hover {
    p {
      color: #7A4199;
    }
  }
}

.container-comment {
  display: flex;
  align-items: center;
  // background-color: blue;
  justify-content: center;
  width: 50%;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    color: rgba(17, 17, 17, 0.5);
  }

  cursor: pointer;

  &:hover {
    p {
      color: #7A4199;
    }
  }
}

/* Progress bar */
.progress {
  margin-top: 20px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}

.progress-bar {
  background-color: white;
}

/* Tooltips */

.bs-tooltip-top .arrow::before {
  border-top-color: #D2D7DE;
}

.tooltip-inner {
  background-color: #D2D7DE;
  color: black;

}

.container-textRich {
  margin-top: 40px;

  ul,
  ol {
    padding: 0px 20px;
    list-style: initial;
  }

  img {
    width: 100%;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }
}

.btn-edit {
  height: 46px;
  border-radius: 10px;
  background-color: white;
  color: #111111;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  border: none;
  margin-top: 20px;
}

.numberStep {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #7A4199;

  &.title {
    color: black;
    margin-left: 5px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
}
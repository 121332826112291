.card-header {
  border-radius: 5px;
  padding: 0.5rem 0rem;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border-bottom: none;
}

.card-body {
  padding: 1rem 0rem;
}

.card {
  display: flex;
  flex-direction: column-reverse;  
  cursor: pointer;  
  border: none;
  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
}



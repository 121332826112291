@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Raleway:wght@600;700&family=Roboto+Mono:wght@500;700&display=swap");

.home-container {
  margin-left: 60px;
  height: 100vh;
  display: flex;
  padding: 30px;
  overflow-y: scroll;
}

.home-welcome {
  width: 70%;

  h1 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
  }

  @media only screen and (max-width: 920px) {
    width: 100%;
  }
}

.home-welcomeMsg {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: #979797;
  margin-top: 5px;
}

/* Progress */

.progressUser-container {
  width: 100%;
  background-color: #eff0f6;
  border-radius: 10px;
  padding-left: 56px;
  padding-right: 13px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  .progressUser-close {
    align-self: flex-end;
    cursor: pointer;
  }

  h5 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
  }
}

.progressUser-containerProgressText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;

  p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    color: #979797;
  }

  span {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0px;
    color: #979797;
  }
}

.progressUser-containerBar {
  width: 80%;
  margin-top: 8px;
  margin-bottom: 26px;
  // background-color: red;
}

.progressUser-lineBar {
  width: 100%;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 40px;

  .progressUser-barProgress {
    background-color: #2c5999;
    height: 6px;
    border-radius: 40px;
  }
}

.progressUser-containerCheck {
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  .progressUser-containerStatus {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #979797;

    &.active {
      background-color: #1db964;
      border: none;
    }
  }
}

.progressUser-checkStatus {
  margin-left: 14px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  color: #152a49;

  &.active {
    text-decoration: line-through;
    color: #2c5999;
  }
}

/* card help */
.userHelp-container {
  width: 100%;
  height: fit-content;
  padding: 30px 15px;
  font-size: 3rem;
  background-color: #33b3d1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  border-radius: 15px;
  flex-wrap: wrap;

  button {
    width: 177px;
    height: 50px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    color: #8945ad;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #8945ad;

    @media only screen and (max-width: 1060px) {
      margin-top: 10px;
    }
  }
}

.userHelp-containerImgAndMsg {
  display: flex;
  width: 70%;
  img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}

.userHelp-containerMsg {
  display: flex;
  flex-direction: column;
  color: white;

  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
  }

  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    margin-top: 8px;
  }
}

/* actions cards */
.homeActions-container {
  display: flex;
  margin-top: 17px;
  justify-content: space-between;

  // flex-wrap: wrap;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.homeActions-cards {
  min-height: 198px;
  background-color: #2c5999;
  border-radius: 15px;
  width: 48%;
  display: flex;
  padding: 15px;

  img {
    width: 50px;
    height: 50px;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.homeActions-titleCard {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 5px;

  h5 {
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    color: white;
  }

  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    color: white;
  }

  button {
    margin-top: 33px;
    width: 177px;
    border-radius: 10px;
    background-color: white;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    color: #7a4199;
    border: 2px solid #8945ad;
    height: 50px;
    cursor: pointer;
  }
}

@media only screen {
  .userHelp-container {
    align-items: center;
    // justify-content: center;
    .userHelp-containerImgAndMsg {
      display: flex;
      flex-direction: column;
    }
  }

  .homeActions-container {
    .homeActions-cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media only screen and (min-width: 64.063em) {
  .userHelp-container {
    align-items: center;
    // justify-content: center;
    .userHelp-containerImgAndMsg {
      display: flex;
      flex-direction: row;
    }
  }
  .homeActions-container {
    .homeActions-cards {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

.loadingHomeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

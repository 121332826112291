:root {
  --regularFontSize: .8rem;
}

.gcp-card {
  width: 13rem;
  --height: 19rem;

  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);


  display: flex;
  flex-direction: column;
  border-radius: 10px;

  overflow: hidden;
  background-color: white;
  filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));

  -webkit-filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));
  border: 1px solid #f3f3f3;
}

/* top section */
.gcp-top {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 7rem;
  padding-left: 1rem;
  padding-right: 1rem
}

.logra-pattern {
  background-image: url('./logra-pattern.svg');
  background-repeat: no-repeat;
  background-size: 120% !important;
}

.gcp-title {
  user-select: none;
  color: white;
  font-weight: 600;
  word-wrap: break-word;
  line-clamp: 2 !important;

  font-size: 1rem;
}

.gcp-btnOverrides>* {
  border-radius: 5px;
  height: 2.25rem;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.gcp-btnOverrides>*:hover {
  background-color: #2c5999;
}

.gcp-btnOverrides2>* {
  color: #2c5999;
  background-color: white;
  border: none;
}

.gcp-btnOverrides2>*:hover {
  background-color: white;
}

.tst {
  border: 1px solid #000;
}

.gcp-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;

  justify-content: space-between;
  padding-right: 1.25rem;
  padding-left: 1rem;
  padding: 0;

  margin-bottom: 1rem;

  padding-left: 2rem;
  padding-right: 2rem;

}

/* bottom section */

.gcp-bottom {
  --height: 13rem;

  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);
}

.gcp-p {
  margin-bottom: auto;
  margin-top: auto;
  font-weight: 600;
  font-size: var(--regularFontSize);
}

.gcp-combo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.gcp-icon {
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
}

.challenge-icon {
  background-image: url('./radar.svg');
  width: 2.5rem;
  height: 2.4rem;
  transform: scale(.7);
}

.gpc-status {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-size: var(--regularFontSize);

  color: #d8ab28;
  background-color: #fbedc5;

  padding: .4rem;

  border-radius: 100px;
}

@media only screen and (max-width: 2600px) {}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1400px) {}
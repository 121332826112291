@import url("https://use.typekit.net/wvx5jby.css");

.landing-content {
  max-width: 1440px;
  background-color: #fdfdfd;
  display: flex;
  height: 100vh;
}

.rowPage {
  margin: 0;
  width: 100%;
}

.columnIntro {
  display: flex;
  flex-direction: column;
  justify-content: center;

  form {
    width: 85%;
  }

  @media only screen and (max-width: 768px) {
    margin: 120px 0;

    form {
      width: 100%;
    }

    .form-group {
      img {
        left: 10px;
      }
    }
  }
}

.logoPage {
  height: 50px;
  width: 110px;
  margin-bottom: 110px;
  margin-left: 10px;
}

.component-min-wrapper {
  padding: 10px;
}

.custom-h1 {
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 120%;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #14142b;

  span {
    color: #33b3d1;
  }

  &__alt {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 120%;
    color: #fff;
    text-align: left;
    margin: 50px auto;
    width: 60%;
  }
}

.custom-h4 {
  color: #999999;
  font-family: "Cy", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
}

.product-description {
  line-height: 2.5;
  color: #999;
}

.stepBoxForm {
  margin-left: 0px;
  padding: 20px;
  width: 100%;
  max-width: 620px;

  label {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    color: #152a49;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-family: "Inter", sans-serif;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      color: rgba(20, 20, 43, 0.5);
    }
  }

  .termAndCondition {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 30px;

    a {
      color: #8945ad;
    }
  }
}

.labelInput {
  font-size: 14px;
}

.main-btn-align {
  margin-top: 10px;
  width: 100%;
}

.btn-primary {
  border-color: #fff !important;
}

.google_btn {
  color: black !important;
  background-color: #2c59991e !important;
}

.google_btn:hover {
  opacity: 0.5;
}

.google_icon {
  width: 1rem;
  margin-right: 0.5rem;
}

.btn-style {
  background-color: #2c5999;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
  transition: all 0.25s ease;
}

.btn-style:hover {
  background-color: #33b3d1;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}

.common-link-1 {
  font-family: "Cy", sans-serif;
  color: #2c5999;
  font-weight: bold;
  text-decoration-line: none;
  line-height: 28px;
  border-bottom: 2px solid #2c5999;
}

.common-link-1:hover {
  text-decoration-line: none;
}

.common-text-2 {
  color: #14142b;
  line-height: 28px;
  font-weight: 400;
}

.stepPage-container {
  max-width: 1440px;
  display: flex;
  justify-content: left;
  height: 100vh;
}

.stepPage-content {
  margin-top: 5%;
  margin-left: 0px;
  padding: 0;

  overflow-y: scroll;
}

.colStepsTactics {
  padding: 0 20%;
  padding-top: 40px;

  .boxBtnTemp {
    display: flex;
    flex-direction: row-reverse;
  }

  h2 {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
  }

  p {
    width: 295px;
    margin-bottom: 25px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 21x;
    color: #000000;
  }

  ul {
    width: 290px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Inter", sans-serif;

    li {
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin: 4px 0;
      padding: 16px 15px;
    }

    li.active {
      // background: #d9dcee;
      background: #f8f8f8;
      border-radius: 6px;
      font-style: normal;
      font-weight: bold;
      color: #000000;
    }

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 800px) {
    padding: initial;
  }
}

.colStepsDescription {
  background-color: #eff0f6;
  padding: 0 3%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;

  h3 {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
  }

  p {
    width: 295px;
    padding: 25px 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }

  ul {
    width: 290px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Inter", sans-serif;

    li {
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin: 4px 0;
      padding: 16px 15px;
    }

    li.active {
      background: #d9dcee;
      border-radius: 6px;
      font-style: normal;
      font-weight: bold;
      color: #000000;
    }
  }
}

.stepBoxForm {
  margin-left: 0px;
  padding: 20px;
  width: 100%;
  max-width: 620px;

  label {
    font-size: 1.0rem;
    font-style: normal;
    font-weight: 700;
    color: #152a49;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-family: "Inter", sans-serif;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      color: rgba(20, 20, 43, 0.5);
    }
  }

  .termAndCondition {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 30px;

    a {
      color: #8945ad;
    }
  }
}

.onTitle {
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  color: #14142b;
  padding: 0px 0px 14px;
  margin-left: 25px;
}

.onSubTitle {
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  color: #999999;
  padding: 6px 0px 9px 0px;
  margin-left: 25px;
}

.startCover {
  background-color: #7a4199;
  padding: 0px 68px;
  min-height: 100vh;
  width: 50%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 1445px) {
    width: 50%;
    padding: 10px;
  }

  @media only screen and (max-width: 1215px) {
    width: 50%;
    padding: 0px;

    .boxSliderImage {
      display: flex;
      justify-content: center;
    }

    .boxSliderParagraph {
      padding: 60px 60px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    img {
      width: 85%;
    }
  }

  @media only screen and (max-width: 1170px) {
    width: 50%;
    padding: 0px;

    .boxSliderImage {
      width: 420px;
      height: 370px;
      display: flex;
      padding: 0;
      justify-content: center;
      flex-direction: column;
    }

    .boxSliderParagraph {
      padding: 60px 170px 0px 97px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    img {
      width: 85%;
    }
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .colStepsDescription {
    display: none;
  }
}

.addTacticIconPlus {
  position: absolute;
  font-weight: bold;
  font-size: 1.25rem;
  right: 0.65rem;
  bottom: 0.3rem;
  z-index: 100 !important;
}

.buttonPickerCategoryContainer {
  display: flex;
}

.buttonCategoryPicker {
  padding: 1rem;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid rgb(123, 123, 123);
  color: rgb(82, 82, 82);
  margin-right: 0.5rem;
}

.offerStepPage-container {
  display: flex;
  justify-content: left;
  height: 100vh;
}

.offer-content {
  height: 100vh;
  overflow-y: scroll;
  margin-left: 0px;
  padding: 0;
}

@media only screen and (min-width: 90.063em) {
  .stepBoxForm {
    width: 100vw;
    margin-right: 0 !important;
  }
}

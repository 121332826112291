.container-home {
  margin-left: 60px;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.container-profile-app {
  margin-left: 60px;
  height: 100vh;
  display: flex;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.container-homeMain {
  width: 90vw;
  padding: 20px;
  padding-right: 20px;
  //temporal
  overflow: auto;

  //temporal
  &> :first-child> :first-child {
    //temp
    box-shadow: -10vw 0vw 0 white, 0vw -10vw 0 white, -5vw -5vw 0 white;
  }

  //temporal
  .tabButton {
    padding-bottom: 0.7rem !important;
    margin: 0;
    width: max-content;
    min-width: max-content;
  }
}

//temp
.subt {
  width: 25% !important;
}

@media only screen and (max-width: 1400px) {
  .container-homeMain {
    width: 98vw;
    padding-right: 20px;
  }

  .head-nav> :nth-child(1)> :nth-child(2) {
    margin-bottom: 1.5rem !important;
  }

  .head-nav> :nth-child(4) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media only screen and(max-width: 1440px) {
  .head-button {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.container-homeTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 20px;
  margin-bottom: 20px;
}

.container-homeBackgroundCard {
  width: 100%;
  height: 221px;
  background-size: cover;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

//temporal
@media only screen and (max-width: 1920px) {
  .banner-width {
    width: 55% !important;
  }

  .banner {
    min-height: 18rem;
    max-height: 18rem;
  }
}

@media only screen and (max-width: 1400px) {
  .banner-container {
    scale: 0.85;
    transform: translateX(-60px);
  }

  .banner-width {
    width: 60% !important;
  }

  .banner {
    min-height: 15rem !important;
    max-height: 15rem !important;
  }
}

// @media only screen and (max-width: 10px) {
//   .banner-content {
//     border: 1px solid #000 !important;

//     &>:nth-child(2) {
//       border: 1px solid #000;
//       margin-top: 0;
//       margin-bottom: 0;
//     }
//   }

// }

.container-home-ShareAndProcess {
  display: flex;
  justify-content: space-between;
}

.container-home-share {
  background-color: white;
  width: 40px;
  height: 40px;
  border: 1px solid black;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: 25px;
  }
}

.container-profile-share {
  background-color: white;
  width: 100%;
  height: 40px;
  border: 1px solid black;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 500;

  img {
    height: 20px;
    margin-right: 10px;
  }
}

.container-home-process {
  background-color: #111111;
  width: 139px;
  height: 22px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: white;
  }
}

.container-home-category {
  background-color: #ecd0dd;
  width: 220px;
  height: 24px;
  border-radius: 3px;

  p {
    color: #dd74a4;
    text-align: center;
    font-weight: bold;
  }
}

.container-home-results {
  margin-top: 10px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.container-homeBodyCard {
  height: 246px;
  width: 100%;
  padding: 25px;
}

.container-home-cardBody-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.container-home-categoryInfo {
  width: 199px;
  height: 31px;
  border-radius: 30px;
  background-color: #dde3e9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  p {
    font-size: 13px;
    color: black;
  }
}

.container-profile-categoryInfo {
  height: 31px;
  border-radius: 30px;
  background-color: #dde3e9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;

  p {
    font-size: 13px;
    color: black;
  }
}

.container-home-infoNumber {
  display: flex;

  p {
    margin-left: 7px;
    font-weight: bold;
    color: #2c5999;
  }
}

.container-home-cardBody-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.container-profile-cardBody-calification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding: 10px;
}

.boxTacticCompletedProfile {
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.container-profile-cardBody-other {
  display: flex;
  background: #f7f7fc;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding: 10px;

  .textData {
    font-size: 14px;
    font-weight: 600;
  }
}

.container-home-cardBody-infoAuthor {
  display: flex;
  align-items: center;

  img {
    width: 30px !important;
    height: 30px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
  }
}

.container-profile-cardBody-infoCalification {
  display: flex;
  align-items: center;

  img {
    width: 30px !important;
    height: 30px;
  }

  p {
    font-size: 14px;
  }
}

.container-home-cardBody-star {
  display: flex;
  align-self: center;
}

.container-profile-cardBody-star {
  display: flex;
  align-self: center;
}

.container-home-cardBody-results {
  margin-top: 15px;
  font-size: 18px;

  span:first-child {
    font-weight: bold;
  }

  span:last-child {
    color: gray;
    margin-left: 5px;
  }
}

.container-home-cardBody-btn {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.GridViewContent {
  display: flex;
  padding-top: 0px;
  column-gap: 50px;
  flex-wrap: wrap;

  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
}

.expertTacticCardNormal {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 16px rgba(17, 17, 17, 0.1);
  width: 379px;
  border-radius: 6px;
  margin-bottom: 20px;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 1000px) {
    width: 350px;
  }

  @media only screen and (max-width: 800px) {
    width: 90%;
  }
}

.expertTacticCardGrid {
  display: flex;
  flex-wrap: wrap;

  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: auto;
  //border: 1px solid #000;
}

.expertTacticCardGrid>* {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  margin: 1.5rem;

  // @media only screen and (max-width: 1000px) {
  //   margin: 2rem;
  //   border: 1px solid #000;
  // }
}

.alert {
  position: absolute;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 320px;
  right: 50%;
  margin-top: 10px;
  margin-right: 5px;
  height: 90px;
  display: flex;
  align-items: center;
  z-index: 2000;

  p {
    width: 260px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
}

.alert-success {
  color: #000;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 6px;
}

.library-btn {
  height: 48px !important;
  background-color: #2c5999 !important;
  width: 12rem !important;
  border-radius: 10px !important;
  border: none !important;
  color: white;
  margin-bottom: 1.1rem;
}

.listOfOfferCards {
  overflow: auto;
}

.stack-control-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.2rem;
  height: 90%;
  justify-content: flex-start;
  align-items: center;

  .stack-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.1rem;
  }
}

.stack-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.stack-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media only screen {
  .responsive-FontSize {
    font-size: calc(1em + 1vw);
  }

  .responsiveDescription-FontSize {
    font-size: calc(0.5em + 1vw);
  }

  .responsiveText-FontSize {
    font-size: calc(0.4em + 1vw);
  }

  .innerContent-Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    height: 15rem;
  }

  .stack-control {
    flex-direction: column;
    margin-top: 1rem;
  }

  .stack-btns {
    margin-top: -1.6rem;
  }
}

.tabcontent-section {
  // display: flex;
  // flex-wrap: wrap;
  // gap: 1rem;
  margin-top: 0.6rem;
  padding-left: 0.3rem;
  min-width: 95% !important;
  max-width: 95% !important;

}

.stack-img-btn {
  width: 2.4rem;
  margin-left: 0.2rem;
  margin-top: 0.3rem;
}

.switchTab-Container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;

  button {
    margin-left: 0;
  }
}

@media only screen and (min-width: 64.063em) {
  .responsive-FontSize {
    font-size: calc(0.3em + 1vw);
  }

  .responsiveDescription-FontSize {
    font-size: calc(0.2em + 1vw);
  }

  .responsiveText-FontSize {
    font-size: calc(0.1em + 1vw);
  }

  .innerContent-Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10rem;
    height: 15rem;
  }

  // .content-container {
  //   width: 50%;
  // }

  .tabcontent-section {
    padding-left: 0;
    margin-top: -3.2rem;
    margin-left: 10px;
  }

  .stack-img-btn {
    width: 2.4rem;
    margin-left: 0.1rem;
    margin-top: 0.3rem;
  }

  .stack-btns {
    margin-top: 0.9rem;
  }

  .stack-control-tab {
    margin-top: -1.6rem;
  }

  .switchTab-Container {
    margin-top: -1.6rem;
  }

  .margin-stack-btns {
    margin-top: -1.6rem;
  }
}

.banner-section {
  width: 50%;
  height: 100%;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto&display=swap');
.container-resourceHome {
  margin-left: 60px;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.container-resourceHomeMain {
  width: 1400px;
  max-width: 1400px;
  padding: 20px;
}

.container-resourceHomeTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 20px;
  margin-bottom: 20px;
}

.container-resourceCardsFlex {
  // background-color: red;
  // display: flex;
  // flex-wrap: wrap;

  // display: grid;
  // padding: 10px;
  // grid-template-columns: repeat(auto-fit, minmax(379px, 1fr));
  // column-gap: 50px;
  // width: 100%;

  display: flex;
  padding-top: 0px;
  column-gap: 50px;
  flex-wrap: wrap;
  @media only screen and (max-width: 800px) {
    justify-content: center;
  }

}
.container-resourceCardsGrid {
  // background-color: red;
  // display: flex;
  // flex-wrap: wrap;

  display: grid;
  padding: 10px;
  grid-template-columns: repeat(auto-fit, minmax(379px, 1fr));
  column-gap: 50px;
  width: 100%;

}
.lograContent-containerCard {
  margin-bottom: 20px;
}

.container-resourceHomeBtns {
  display: flex;
}

.resourceHome-btnContent {
  height: 50px;
  background-color: white;
  border: 2px solid #8945AD;
  border-radius: 10px;
  width: 241px;
  color: #7A4199;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 50px;
}

.resourceHome-btnTactic {
  height: 50px;
  background-color: #8945AD;
  border: none;
  border-radius: 10px;
  width: 241px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;

  margin-top: 50px;
  margin-left: 20px;
}

.container-resourceHome-sections {
  display: flex;
  margin-bottom: 31px;
}

.resourceHome-btnSectionTactic {
  border: none;
  height: 46px;
  width: 189px;
  background-color: #EFF0F6;
  color: #979797;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;

}

.resourceHome-btnSectonIndex {
  height: 46px;
  width: 189px;
  border: none;
  background-color: #DCE9FC;
  color: #2C5999;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  margin-left: 40px;
}

/* table */
.resourceTable-edit {
  border-radius: 6px;
  border: 2px solid #7A4199;
  background-color: white;
  width: 87px;
  height: 30px;
  color: #7A4199;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  
  &:hover {
    background-color: #2C5999;
    color: white;
  }
}

.resoruceTable-statusIncomplete {
  width: 98px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1C33D4D;
  color: #D4A212;
  border-radius: 30px;

  p {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    
  }

}

/* my switch */

.container-resourceTable-switch {
  height: 36px;
  width: 69px;
  border-radius: 30px;
  border: 1px solid #D2D7DE;
  display: flex;
  align-items: center;
  padding: 5px;
  &.active {
    justify-content: flex-end;
  }
  
}

.resourceTableSwitch {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  cursor: pointer;
  background-color: #979797;
  // &.noActive {
  // }  
  &.active {
    background-color: #1FAB08;
  }  
}
.btnPicker-container {
  margin-top: .5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.buttonPicker {
  width: fit-content;
  margin-right: .5rem;
}

.buttonPicker input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.buttonPickerLabel {
  display: inline-block;
  background-color: transparent;
  padding: .75rem 1rem;
  border: 1px solid #dddfe8;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;
}

.withTempOverrides {
  padding: .15rem 1rem !important;
  border-radius: 15px;
}

.MustPickACategory {
  padding: .75rem 1rem;
  border-radius: 15px;
  background-color: #dddfe8;
  border: 1px solid transparent;
  opacity: 0.4 !important;
  cursor: wait;
}


.buttonPickerLabel:hover {
  background-color: transparent;
  border: 1px solid #b9bbc3;
}

.buttonPicker input[type="radio"]:checked+.buttonPickerLabel {
  background-color: #dddfe8;
  border: 1px solid transparent;
}
.lograModal-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  z-index: 20 !important;
  background-color: rgba(0, 0, 0, 0.15);
}

.baseLograModal {
  min-width: 70vw;
  width: 70vw;
  /* height: fit-content; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  border-radius: 10px;

  padding: 3rem;
  display: flex;
  flex-direction: column !important;
  align-items: center;

  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  animation-name: popIn;
  animation-duration: .6s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-iteration-count: 1;
}


.baseTempModal {
  height: fit-content;
  width: 40vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  border-radius: 10px;

  padding: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  animation-name: popIn;
  animation-duration: .6s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-iteration-count: 1;
}

@keyframes popIn {
  0% {
    top: 40%;
    opacity: 0;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
}

@media only screen and (max-width: 1370px) {
  .baseTempModal {
    width: 40vw !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lograModal-head {
    width: 90% !important;
  }

  .lograModal-title {
    font-size: 1.5vw;
  }

  .lograModal-subtitle {
    font-size: 1vw;
  }
}

@media only screen and (min-width: 1400px) {
  .baseTempModal {
    width: 40vw !important;
  }
}

@media only screen and (min-width: 1920px) {
  .baseTempModal {
    width: 35vw !important;
  }
}

.lograModal-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
}

.lograModal-title {
  /* font-size: 3rem; */
  font-size: 2vw;
  font-weight: 700;
  color: #14142a;
  margin-bottom: 1rem;
}

.lograModal-subtitle {
  /* font-size: 2.5vw; */
  font-size: 1.5vw;
  font-weight: 400;
  color: black;
}

.lograModal-btn-container {
  margin-top: 1rem;
  width: 40%;
}

.lograModal-close-btn {
  position: absolute;
  padding: 0;
  margin-right: 3rem;
  margin-top: 3rem;
  top: 0;
  right: 0;
  transform: scale(1.5);
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.lograModal-close-btn:hover {
  opacity: 0.5;
}

.lograModal-close-btn:active {
  opacity: 0.25;
}

/* .slides_container:first-child {} */

.lograModal-slide {
  width: auto;
  height: 50vh;
  display: flex;
  align-items: center;
}

.lograModal-slide-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* align-items: center !important; */
  margin-top: auto;
  margin-bottom: auto;
  user-select: none;
}

.lograModal-media {
  width: 50% !important;
  height: auto;
  object-fit: cover;
  margin-right: 3rem;
}

.lograModal-slide-content {
  width: 50% !important;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3rem;
}

.lograModal-pagination {
  width: 1rem;
  height: 1rem;
  border-radius: 100rem;

  margin-top: 1rem;
  margin-bottom: 1rem;
}

.lograModal-pagination-active {
  background-color: #2c5999
}

.lograModal-pagination-inactive {
  background-color: #c4c4c4;
}

/* @media only screen and (max-width: 1060px) {} */

.lograModal-hide {
  display: none;
}

@media only screen and (max-height: 700px) {

  .lograModal-title-slides {
    margin-bottom: 1rem !important;
  }

  .lograModal-slide-container {
    padding-top: -0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 1400px) {

  .baseLograModal {
    min-width: 65vw;
    width: 65vw;
    padding: 2rem;
  }

  .lograModal-title-slides {
    font-size: 2vw;
    font-weight: 700;
    color: #14142a;
    margin-bottom: -2rem;
  }

  .lograModal-slide-container {
    width: initial !important;
    margin-bottom: -.5rem;
    margin-top: .5rem;
  }

  .lograModal-media {
    margin: 0;
    margin-right: 1rem;
  }

  .lograModal-slide-content {
    margin-top: auto;
    margin-bottom: auto;
  }

  .responsiveOverrides {
    font-size: 1.25vw !important;
  }

  .lograModal-close-btn {
    padding: 0;
    margin-top: 1rem;
    margin-right: 1rem;
    transform: initial;
  }

}
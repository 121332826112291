/* CSS */

.helper_box {
  font-family: "Inter", sans-serif;
  border: 1px solid #979797;
  background-color: #ffff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.helper_content {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.helper_tick {
  margin-right: 8px;
  pointer-events: initial;
}

.helper_item_text {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.helper_title {
  font-size: 16px;
  padding-right: 12px;
  padding-left: 12px;
}

.helper_list_text {
  font-size: 14px;
  margin: 2;
  padding: 0;
}

.helper_example_title {
  font-weight: 700;
}
.helper_example_item {
  font-size: 14px;
}
.render {
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease;
}

.show {
  visibility: visible;
  opacity: 1;
}

.helper_dropdown {
  width: 18px;
  height: 18px;
}

.modal-tacticGeneral {
  max-width: 1063px;
  @media only screen and (max-width: 1115px) {
    max-width: 900px;
  }
  @media only screen and (max-width: 920px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 780px) {
    max-width: 580px;
  }
}
.container-modalGeneral {
  padding: 0;
}

.container-generalFirst {
  background-size: cover;
  padding: 30px;

}
.container-generalActions {
  display: flex;
  justify-content: space-between;
  
}
.container-generalPlan {
  display: flex;
  img {
    height: 24px;
    width: 24px;
  }
}
.container-generalShare {
  display: flex;
  width: 158px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.3);

  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  img {
    color: white;
  }
  p {
    margin-left: 10px;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
  }
  cursor: pointer;

}
.container-generalShare-public {
  @extend .container-generalShare;
  @media only screen and (max-width: 695px) {
    margin-top: 10px;
  }
}

.general-tacticTitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: white;
  margin-top: 30px;

  @media only screen and (max-width: 450px) {
    font-size: 28px;
  }
}

.container-generalProgress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    color: white;

  }
}

.container-generalSecond {
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }
}

.general-separator {
  border: 1px solid #D2D7DE;
  margin-top: 22px;
  @media only screen and (max-width: 780px) {
    margin-top: 10px;
  }
}

.container-generalMoreDetails {
  width: 100%;
  
}
.container-generalStepDescription {
  padding: 5px;
}
.container-generalProfileAndInfoTactic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 920px) {
    flex-wrap: wrap;
  }
}

.container-generalExpert {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }
  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    color: black;
    margin-left: 10px;
    width: 150px;
  }
}

.container-generalInfoTactic {
  border: 1px solid #D2D7DE;
  border-radius: 6px;
  height: 90px;
  display: flex;

  @media only screen and (max-width: 503px) {
    margin: 20px 0px;
  }
  

  /* responsive */
}
.container-generalInfoEach{
  display: flex;
  flex-direction: column;
  width: 106px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #D2D7DE;
  img {
    width: 20px;
    height: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    margin-top: 16px;

  }
  &.completed {
    p {
      color: #2C5999;
    }
  }
  &.time {
    p {
      color: #7A4199;
    }
  }
  &.star {
    border-right: initial;
    p {
      color: #F1C33D;
    }
  }


  @media only screen and (max-width: 1115px) {
    width: 80px;
  }
}
.container-generalStar{
  display: flex;
  flex-direction: column;
  width: 160px;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    margin-top: 16px;
    color: #F1C33D;

  }
}

.container-generalStars {
  display: flex;
}
.container-generalResults {
  background-color: #DDE3E9;
  padding: 20px;
  width: 40%;
  border-radius: 15px;

  .general-resultTitle {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    color: black;
  }

   @media only screen and (max-width: 500px) {
    width: 100%;
  }
}

.general-skillsTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  margin-top: 25px;
}

.general-aboutTactic {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 14px;
}
.general-close {
  cursor: pointer;
}
.general-btnEdit {
  height: 40px;
  border-radius: 10px;
  background-color: white;
  color: #111111;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  border: none;
  margin-right: 35px;
  width: 158px;
}
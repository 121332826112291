.visibleSuccess {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
.hiddenSuccess {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.success-btn {
  background-color: #2c5999;
  color: #f7f7fc;
  width: 200px;
  line-height: 28px;
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
  transition: 0.3s;
  font-size: 0.9em;
}
.success-btn:hover {
  background-color: #f7f7fc;
  color: #2c5999;
  border: 2px solid #2c5999;
}

.redirect-btn {
  border: 2px solid #2c5999;
  color: #2c5999;
  font-size: 0.9em;
  width: 200px;
  background: #f5f5f5;
  line-height: 28px;
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
  transition: 0.3s;
}

.redirect-btn:hover {
  background-color: #2c5999;
  color: white;
}

.success-imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;

  .success-img {
    max-width: 100%;
  }
}

.successContainer {
  display: flex;

  h1 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #152a49;
  }
  p {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 700;
    color: #152a49;
  }
}
.successColumn {
  margin: 0.5em;
  display: flex;
  margin-left: 1.9rem;
  flex-direction: column;
  justify-content: center;

  .successOfferParagraph {
    font-weight: 400;
  }
}
.successLogoImageContainer {
  width: 100px;
  height: 75px;
  img {
    width: 80%;
    height: 80%;
  }
}

.succesfullOfferImage {
  max-width: 100%;
  width: 68%;
  height: 45;
}

@media only screen {
  .buttonContainer {
    display: flex;
    flex-direction: column;
  }
  .succesfullOfferImage {
    max-width: 100%;
    width: 90%;
    height: 45;
  }
  .success-imgContainer {
    display: none;
  }
}

@media only screen and (min-width: 64.063em) {
  .successContainer {
    overflow: auto;
    height: 100vh;
    width: 100vw;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
  }
  .success-imgContainer {
    display: block;
  }
}

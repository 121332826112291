.google_button_overrides {
  width: 100%;
  margin-top: 1rem;

  display: flex;
  justify-content: center;
  /*background-color: #2c5999 !important;*/
  color: black;

  background-color: #2c59991c !important;
  border-radius: 4px !important;


  box-shadow: 0 0 0 !important;

}

.google_button_overrides>div {
  background-color: transparent !important;
}
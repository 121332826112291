.toolModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 10;

  animation: fadeIn;
  animation-timing-function: ease-in-out;
  animation-duration: .25s;
  animation-iteration-count: 1;

}

.toolModalLogoPicker {
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 34rem;
  height: 34rem;
  border-radius: 10px;
  padding: 2rem;
  background-color: white;
  z-index: 100;
  //  align-items: baseline;

  animation: moveIn;
  animation-timing-function: ease-in-out;
  animation-duration: .30s;
  animation-iteration-count: 1;
}

.toolModalHeader {
  display: flex;
  flex-direction: row;

  h4 {
    font-weight: 'bold'
  }

  .img {
    cursor: pointer;

    &:hover {
      opacity: .7
    }

    &:active {
      opacity: 1
    }
  }

  img {
    cursor: pointer;

    &:hover {
      opacity: .7
    }

    &:active {
      opacity: 1
    }
  }
}

.toolModalContainer-imageResultscontainer {
  height: 21.5rem;
  min-height: 21.5rem;
  max-height: 21.5rem;
  padding-top: 0rem;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: scroll;
  border: 1px solid #e9e9e95d;
  overflow-x: hidden;
}

.toolModalHeader-ImageResult {
  width: 7rem;
  height: 7rem;
  padding: .5rem;
  background-color: rgb(236, 236, 236);
  border-radius: 6px;
  cursor: pointer;
  transition: .25s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 7rem;
  }

  &:hover {
    opacity: .75;
  }
}

.tmh-ImageSelected {
  background-color: #7a4199;
  outline: 2px solid #7a4199;
}

.tmh-Loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes moveIn {
  from {
    transform: translate(-50%, -100%);
  }

  to {
    transform: translate(-50%, -50%);
  }
}


.toolModalContainer-noResults {

  padding: 1rem;

  .tmc-nr-head {
    font-size: 20px;
    font-weight: bold;
    margin-top: 1rem;
    color: #2c5999;
  }

  .tmc-nr-sub-head {
    margin-top: 1rem;
    margin-bottom: 1rem;
    opacity: 0.7;
  }

  .tmc-nr-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .tmc-nr-image {
    width: 8rem;
    height: 8rem;
    border: 1px solid #7a4199;
    background: #7a4199;
    margin-right: 1rem;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    >img {
      width: 100%;
      height: auto;
    }

    .tmc-nr-image-placeholder {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #b9b9b9;

      img {
        // width: 'auto',
        // height: '100%',
        // opacity: '0.3'
        object-fit: contain;
        width: 100%;
        height: auto;
        filter: invert(100%);
        opacity: 0.4;
      }

    }
  }

  .tmc-nr-button {
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #ECECEC;
    color: #232323;
    justify-content: center;
  }

}

.tmh-logoRender {
  overflow: hidden;
  width: 100%;
  height: auto;
}
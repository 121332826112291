.planning-theme {
  background-color: #f5cece;
  color: #e16b67;
}
.team-theme {
  background-color: #c2c9f0;
  color: #5c6caeb2;
}
.marketing-theme {
  background-color: #faddb9;
  color: #efa54d;
}
.admi-theme {
  background-color: #ecd0dd;
  color: #dd74a4;
}
.tech-theme {
  background-color: #d3b7d6;
  color: #a77bad;
}

.container-tactic {
  margin-left: 60px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    overflow-y: scroll;
    align-items: center;
    //  width: 100%;
  }
}

.container-tacticForm {
  width: 60%;
  padding: 0px 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    overflow-y: initial;
    width: 100%;
  }
}
.container-tacticBtn {
  background-color: white;
  width: 40%;
  @media only screen and (max-width: 800px) {
    align-self: flex-start;
    width: 90%;
    padding: 0 20px;
    margin-bottom: 20px;
  }
}

.container-tacticCategory {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0px;
  cursor: pointer;
}

.tactic-category {
  width: 211px;
  background-color: #eff0f6;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-top: 10px;
  margin-right: 10px;
  border: none;
  color: #979797;

  &.planning {
    &.active {
      @extend .planning-theme;
    }
    &:hover {
      @extend .planning-theme;
    }
  }
  &.team {
    &.active {
      @extend .team-theme;
    }
    &:hover {
      @extend .team-theme;
    }
  }
  &.marketing {
    &.active {
      @extend .marketing-theme;
    }
    &:hover {
      @extend .marketing-theme;
    }
  }
  &.resumen {
    &.active {
      @extend .tech-theme;
    }
    &:hover {
      @extend .tech-theme;
    }
  }
  &.admi {
    &.active {
      @extend .admi-theme;
    }
    &:hover {
      @extend .admi-theme;
    }
  }
  &.tech {
    &.active {
      @extend .tech-theme;
    }
    &:hover {
      @extend .tech-theme;
    }
  }
}

.tactics-label {
  display: flex;
  // align-items: center;
  span {
    color: #616161;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 2px;
  }
  p {
    margin-left: 5px;
  }
  img {
    margin-left: 5px;
    cursor: pointer;
  }
}

.container-tacticBusinessStage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0px;
  cursor: pointer;
}

.tactic-businessStage {
  border: 1px solid black;
  width: 146px;
  height: 120px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;

  p {
    margin-top: 5px;
  }
}

.tactic-businessStage.active {
  border: 2px solid #7a4199;
  p {
    color: #7a4199;
  }
}

.tactic-addTool {
  color: #2c5999;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 31px;
}

.tactic-checkbox {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  cursor: pointer;
  p {
    margin: 0px 10px;
  }
  // img {
  //   margin-left: 10px;
  // }
}

.tactic-containerCheckbox {
  height: 32px;
  width: 32px;
  background-color: #2c5999;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tactic-containerCheckboxInactive {
  height: 32px;
  width: 32px;
  background-color: #d6d8e7;
  border-radius: 100%;
}

.number-step-tactic {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.tacticEdit-titleDescription {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
}
.tacticEdit-textDescription {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}

.container-countries {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tacticEdit-listContainer {
  width: 100%;
  // background-color: #2C5999;
  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
  margin-bottom: 20px;
  height: 64px;
  border-radius: 15px;
  display: flex;
  padding: 0px 20px;
  align-items: center;

  img {
    margin-right: 10px;
    cursor: pointer;
  }
}

.container-titleTool {
  display: flex;
  align-items: center;

  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  p {
    margin-left: 8px;
  }
}

.container-tool {
  height: 60px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #000000;
  margin: 15px 0px;
  cursor: pointer;

  display: flex;
  justify-content: space-around;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.modalCongrats-ReturnBtn {
  padding: 1rem;
  border-radius: 10px;
  background-color: #ffff;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: solid 1px gainsboro;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
  height: 45px;
  /*max-width: 420px;*/
  width: 100%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.modalCongrats-ReturnBtn:hover {
  background-color: #e2e2e2;
  align-items: center;
  background: #dbdbdb;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
}

.TacticCard-temp {
  width: 24rem;
  min-height: 26.5rem;
  max-height: 26.5rem;

  display: flex;
  flex-direction: column;
  border-radius: 10px;

  overflow: hidden;
  background-color: white;
  filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));

  -webkit-filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));
  border: 1px solid #f3f3f3;

  margin-bottom: 20px;
}

/* top section */

.tactic-top {
  padding: 1rem !important;
}

.logra-pattern {
  background-image: url("./logra-pattern.svg");
  background-repeat: no-repeat;
  background-size: 120% !important;
}

.share-btn {
  width: 3rem;
  height: 3rem;

  border-radius: 10px;
  border: 2px solid white;
  color: white;
  background-color: rgba(255, 255, 255, 0.4);

  margin-bottom: 3rem;
}

.share-icon {
  margin-left: auto;
  background-image: url("./share.svg");
  margin-top: 0.35rem;
  margin-right: 0.2rem;
  transform: translateX(2px);
}

.share-btn:hover {
  opacity: 80%;
}

.share-btn:active {
  background-color: rgba(255, 255, 255, 0.678);
}

.category {
  display: flex;
  text-align: center;

  width: fit-content;

  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.2);

  padding: 0.15rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;

  border-radius: 5px;
}

.tactictemp-title {
  cursor: pointer;
  user-select: none;

  color: white;
  font-weight: bold;
  word-wrap: break-word;
  line-clamp: 2 !important;
  text-overflow: ellipsis !important;

  overflow: hidden;

  font-size: 1.3rem;
  min-height: 3.5rem;
  max-height: 3.5rem;

  border: 1px solid transparent;
}

.tactic-title:hover {
  opacity: 0.7;
}

.tactic-title:active {
  opacity: 1;
}

.tactic-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  padding-right: 1.25rem;
  padding-left: 1rem;

  margin-bottom: 1rem;
}

/* bottom section */

.tactic-row:last-child {
  margin-top: 2rem;
}

.subcategory {
  display: flex;
  text-align: center;

  width: fit-content;

  color: black;
  background-color: #dde3e9;

  padding: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;

  border-radius: 100px;
}

.tct-p {
  margin-top: auto;
  margin-bottom: auto;
}

.tactic-bottom {
  padding: 1rem;
  outline: 1px solid white;
}

.tactic-user {
  display: flex;
  flex-direction: row;
}

.tactic-user>p {
  font-weight: bold;
}

.tactic-user-picture {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 100px;
  overflow: hidden;
}

.tactic-no-user-pic {
  background-image: url("./user.svg");
  filter: sat(0%);
}

.icon-combo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.tactic-icon {
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.views-container {
  /* color: #2c5999; */
  font-weight: bold;
}

.views-icon {
  background-image: url("./user.svg");
  transform: translate(10%, 10%) scale(1.1);
  margin-left: 5px;
}

.ratings-icon {
  background-image: url("./star.svg");
  margin-right: 0.25rem !important;
  transform: translate(7px, 5px) scale(1.4) !important;
}

.ratings-container {
  color: #f1c33d;
  font-weight: bold;
}

.edit-btn {
  width: 6rem !important;
  height: 2.25rem !important;

  border-radius: 7px;

  border: 2px solid #2c5999 !important;
  color: #2c5999;

  font-weight: bold;
  padding: 0px;
}

.edit-btn:hover {
  opacity: 0.5;
}

.edit-btn:active {
  transition: 0.1s !important;
  opacity: 0.3;
}

.status {
  display: flex;
  text-align: center;

  width: fit-content;

  color: #d8ab28;
  background-color: #fbedc5;

  padding: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;

  border-radius: 100px;
}

.status-active {
  color: #28ae12 !important;
  background-color: #bce6b5 !important;
}

/* .status-incomplete {
  border: 1px solid #000;
} */

/* List stylings */

.TacticList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;


  width: 100% !important;

  margin-right: 0;
}

.list-title-col {
  width: 35%;
  margin-right: 1rem;
}

.list-top-col {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
}

.list-top-col>.category {
  /* color: #dd74a4 !important; */
  background-color: #ecd0dd;
  margin-right: 0.5rem;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.list-top-col>.subcategory {
  margin-top: auto;
}

.list-title {
  cursor: pointer;
  user-select: none;

  font-size: 1.65rem;

  color: white;
  font-size: 1.65rem;

  font-weight: bold;

  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  text-overflow: ellipsis;
}

.list-title:hover {
  opacity: 0.7;
}

.list-title:active {
  opacity: 1;
}

.hide {
  display: none;
}

/* temp fixes */
.category {
  font-size: 1vw !important;
  color: #ffffff;
}

/* temp fixes */
.subcategory {
  font-size: 0.8vw !important;
}

@media only screen and (max-width: 2600px) {

  /* temp fixes */
  .category {
    font-size: 1rem !important;
  }

  /* temp fixes */
  .subcategory {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 1440px) {

  /* temp fixes */
  .category {
    font-size: 0.8rem !important;
  }

  /* temp fixes */
  .subcategory {
    font-size: 0.7rem !important;
  }
}

@media only screen and (max-width: 1400px) {
  .list-top-col>.category {
    font-size: 1vw !important;
  }

  .list-top-col>.subcategory {
    font-size: 0.8vw !important;
  }

  .tct-p {
    font-size: 1.2vw !important;
  }

  .TacticList:first-child {
    padding-top: 1rem !important;
  }

  .list-head {
    padding-top: 0 !important;
  }

  .stack-List {
    gap: 0;
  }

  .tactictemp-title {
    font-size: 1rem;
    min-height: 2.6rem;
    max-height: 2.6rem;
  }

  /*  */
  /* small tactic card version */
  .TacticCard-temp {
    width: 17rem !important;
    min-height: 20rem;
    max-height: 20rem;
  }

  .share-btn {
    width: 2rem !important;
    height: 2rem !important;
    margin-bottom: 3rem;
    border-radius: 7px !important;
  }

  .share-icon {
    transform: translate(-2px, -2px) scale(0.8) !important;
  }

  .logra-pattern {
    background-size: 100% !important;
  }

  .tactic-top> :nth-child(1) {
    margin-bottom: 2rem;
  }

  .tactic-title {
    max-height: 3.1rem !important;
    min-height: 2.5rem !important;
    font-size: 1rem !important;
  }

  .category {
    margin-bottom: 0.5rem !important;
    font-size: 0.8rem !important;
  }

  .subcategory {
    padding: 0.3rem !important;
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
    font-size: 0.7rem !important;
  }

  .tactic-bottom {
    padding-bottom: 0 !important;
  }

  .tactic-user-picture {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    border-radius: 100px;
    overflow: hidden;
  }

  .owner {
    font-size: 0.8rem !important;
  }

  .icon-combo .tct-p {
    font-size: 0.8rem !important;
  }

  .tactic-user-picture {
    width: 1.75rem !important;
    height: 1.75rem !important;
  }

  .views-icon {
    transform: scale(0.8) !important;
    margin-right: 0 !important;
  }

  .ratings-icon {
    margin-right: 0.25rem !important;
    width: 1.3rem !important;
    height: 1.5rem !important;
  }

  .status {
    padding: 0.2rem !important;
    padding-top: 0.3rem !important;
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
    font-size: 0.8rem;
  }

  .edit-btn {
    width: 6rem !important;
    height: 2.25rem !important;
    scale: 0.8;
    transform: translateX(11px);
  }

  .tactic-row:last-child {
    margin-top: 0 !important;
  }

  /*

tactic-top
  logra-pattern
share-btn
  share-icon
  tactic-icon
tct-p
  category
title
  tactic-title

tactic-bottom
  row
  tactic-row
  subcategory

icon-combo
  views-container
  views
  views-icon
  tactic-icon

tactic-user
  tactic-user-picture
  owner

ratings-container
  ratings
  ratings-icon

  status
    status-active
edit-btn */
}

.resourceCardContainer {
  display: flex;
  flex-direction: row;
}

.resourceGrid {
  display: flex;
  flex-wrap: wrap;

  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: auto;
}

.resourceList {
  border: 1px solid #000;
}

/* colors */

.tct-p.category.list-sub.planning-theme {
  color: #e16b67 !important;
}

.tct-p.category.list-sub.team-theme {
  color: #5c6caeb2 !important;
}

.tct-p.category.list-sub.marketing-theme {
  color: #efa54d !important;
}

.tct-p.category.list-sub.admi-theme {
  color: #dd74a4 !important;
}

.tct-p.category.list-sub.tech-theme {
  color: #a77bad !important;
}

.tct-p.category.planning-theme {
  background-color: #f5cece;
  color: #e16b67 !important;
}

.tct-p.category.team-theme {
  background-color: #c2c9f0;
  color: #5c6caeb2 !important;
}

.tct-p.category.marketing-theme {
  background-color: #faddb9;
  color: #efa54d !important;
}

.tct-p.category.admi-theme {
  background-color: #ecd0dd;
  color: #dd74a4 !important;
}

.tct-p.category.tech-theme {
  background-color: #d3b7d6;
  color: #a77bad !important;
}
.mainSliderContainer {
  width: 100%;
  padding: 20px 0px;
}

.awssld__content {
  background-color: transparent;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.awssld__bullets {
  --control-bullet-color: #fff;
  --control-bullet-active-color: #36c4dc;
  --content-background-color: #fff;
  position: absolute;
  z-index: 2000;
  bottom: 70px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-row {
  width: 585px;
  margin: 0 auto;
}

.slider-paragraph-1 {
  width: 100%;
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #ffffff;
}

.boxSliderImage {
  width: 585px;
  height: 436px;
  margin: 0 auto;
}

.boxSliderParagraph {
  width: 585px;
  margin: 15px auto;
  padding: 60px 10px;
}

.slider-paragraph-2 {
  width: 100%;
  margin: 10px 0px;
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #ffffff;
}

.slider-paragraph-3 {
  width: 100%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}

.page1 {
  background: #00558b;
  color: #fff;
  font-size: 36px;
  line-height: 100px;
  margin: 0 10px !important;
  padding: 2%;
  position: relative;
  text-align: center;
}

/* Dots */
.custom-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin-top: 1em;
  list-style: none;
  text-align: center;
}

.custom-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.custom-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.custom-dots li button:hover,
.custom-dots li button:focus {
  outline: none;
}

.custom-dots li button:hover:before,
.custom-dots li button:focus:before {
  opacity: 1;
}

.custom-dots li button:before {
  font-family: "slick";
  font-size: 60px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-dots li.carousel-dots-active button:before {
  color: #36c4dc;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Raleway:wght@600;700&family=Roboto+Mono:wght@500;700&display=swap');

.logradorFinal-container {
  margin-left: 60px;
  height: 100vh;
  padding: 30px;

  overflow-y: scroll;
}

.logradorFinal-containerSections {
  display: flex;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.logradorFinal-containerInfo {
  width: 50%;

  h1 {
    margin-top: 32px;
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
  }

  p {
    margin-top: 12px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
  }

  h4 {
    margin-top: 64px;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 44px;

  }


  @media only screen and (max-width: 800px) {
    width: 100%;
  }
}

.logradorFinal-cardActions {
  border-radius: 15px;
  background-color: #2C5999;
  min-height: 198px;
  padding: 17px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  button {
    border-radius: 10px;
    background-color: white;
    color: #7A4199;
    border: 2px solid #8945AD;
    height: 50px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    margin-top: 29px;
    width: 177px;
    align-self: flex-end;
    cursor: pointer;
  }
}

.logradorFinal-actionTitleAndImg {
  display: flex;
  align-items: center;
}

.logradorFinal-actionTitleAndSub {
  margin-left: 15px;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    color: white;
  }

  span {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    color: white;

  }
}

.logradorFinal-img {
  margin-left: 118px;

  @media only screen and (max-width: 1220px) {
    img {
      width: 70%;
    }
  }
}
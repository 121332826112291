.banner-container {
  user-select: none;
}

.banner-width {
  width: 50% !important;
}

.banner {
  height: auto;
  min-height: 22rem;
  max-height: 22rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 20px;
}

.banner-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-content: center;
  color: white;
  text-align: left;
  border: none !important;
}

.banner-content > * {
  line-height: 110%;
}

.banner-title {
  font-size: 1.5vw;
  font-weight: 600;
}

.banner-sub-title {
  font-size: 1vw;
  margin-top: 1.75rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.banner-media {
  width: 30% !important;
  height: auto;
  object-fit: cover;
}

.banner-close-btn {
  position: relative;
  float: right;
  top: 4rem;
  right: 2rem;
  z-index: 1;
  border: none;
  filter: invert(100%);
  background-color: transparent;
}

.banner-logra-pattern {
  background-image: url(../../assets/images/icons/Logra_pattern.svg);
  background-repeat: repeat-x;
  background-size: 50% !important;
}

.paginationContainer {
  bottom: 0;
  width: 10%;
}

.pagination {
  width: 1rem;
  height: 1rem;
  border-radius: 100rem;
}

.pagination_active {
  background-color: #2c5999;
}

.pagination_inactive {
  background-color: #c4c4c4;
}

/* @media only screen and (max-width: 1060px) {} */

// @media only screen and (max-width: 1366px) {
//   .banner {
//     padding: 2rem !important;
//     min-height: 14rem;
//     max-height: 14rem;
//   }

//   .banner-width {
//     width: 65% !important;
//   }

//   .banner-content {
//     width: 60%;
//   }

//   .banner-media {
//     margin-right: 1rem;
//   }

//   .banner-title {
//     font-size: 1.5vw;
//     font-weight: 600;
//   }

//   .banner-sub-title {
//     font-size: 1.25vw;
//     margin-top: 1rem;
//     margin-bottom: 2rem;
//   }

//   .banner-close-btn {
//     position: relative;
//     float: right;
//     top: 3rem;
//     right: 1rem;
//     transform: scale(0.8);
//     z-index: 1;
//     border: none;
//     filter: invert(100%);
//     background-color: transparent;
//   }
// }

@media only screen {
  .banner-section {
    width: 90%;
  }
  .banner-carousel {
    height: 100%;
  }
  .banner-container {
    margin-left: 2rem;
    min-width: 21rem;
  }
  .banner-content {
    width: 100%;
    button {
      margin-top: -1rem;
    }
  }

  .banner-content > * {
    line-height: 90%;
  }

  .banner {
    width: 100%;
    padding: 0.4rem;
  }
  .banner-media {
    margin-right: 1rem;
  }
}

@media only screen and (min-width: 64.063em) {
  .banner-section {
    padding: 1.2rem;
    width: 55%;
  }
  .banner {
    padding: 0.7rem;
    width: 100%;
    min-height: 14rem;
    max-height: 14rem;
  }

  .banner-container {
    margin-left: 0.5rem;
    min-width: 21rem;
  }
  .banner-content {
    width: 100%;
    button {
      margin-top: 0.5rem;
    }
  }

  .banner-media {
    margin-right: 1rem;
  }

  .banner-title {
    font-size: 1.5vw;
    font-weight: 600;
  }

  .banner-sub-title {
    font-size: 1.25vw;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .banner-close-btn {
    position: relative;
    float: right;
    top: 3rem;
    right: 1rem;
    transform: scale(0.8);
    z-index: 1;
    border: none;
    filter: invert(100%);
    background-color: transparent;
  }
}

.sidebar__tactic-container-tacticInfo {
  width: 30%;
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;

}

.sidebar__tactic-container-infoExpert {
  background-color: white;
  width: 100%;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: center;
  border: 1px solid #D2D7DE;

  img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }

  .name-expert {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    color: color;
    margin-left: 10px;
  }

}
.modal-content {
  border-radius: 10px;
}

.modalStep-close {
  align-self: flex-end;
  cursor: pointer;
}

.modalStep-title {
  // font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin-bottom: 32px;
}

.modalStep-form {
  align-self: center;
  // padding: 100px;
  width: 70%;
}

.modalStep-file {
  display: initial !important;
  padding: 5px;
}

.modalStep-containerFileUP {
  display: flex;
  align-items: center;

  p {
    color: #2c5999;
    font-style: italic;
    font-size: 15px;
  }

  a {
    // margin-left: 5px;
    color: #2c5999;
    font-style: italic;
    text-decoration: underline;
    font-size: 15px;
  }
}

.modalStep-fileUp {
  color: #2c5999;
  font-style: italic;
  font-size: 15px;

}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Raleway:wght@600;700&family=Roboto+Mono:wght@500;700&display=swap');

.logrador-container {
  margin-left: 60px;
  height: 100vh;
  display: flex;

  @media only screen and (max-width: 825px) {
    flex-direction: column;
    overflow-y: scroll;
  }

}

.logrador-sectionInfo {
  width: 75%;
  background-color: white;
  padding: 30px;
  overflow-y: scroll;

  h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    color: #152A49;
    margin-bottom: 8px;
    margin-top: 32px;
  }

  @media only screen and (max-width: 1100px) {
    width: 65%;
  }

  @media only screen and (max-width: 825px) {
    width: 100%;
    overflow-y: initial;
  }

}

.logrador-cardMsg {
  background-color: #EFF0F6;
  border-radius: 15px;
  padding: 20px 40px;
  margin-top: 30px;
  // width: fit-content;
  width: 60%;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #666666;
  }

  @media only screen and (min-width: 1400px) and (max-width: 1800px) {
    width: 80%;
  }
  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
}

.logrador-titleCard {
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    color: #152A49;

    span {
      color: #2C5999;
    }

  }
}

.logrador-listInfo {
  list-style: none;
  margin-left: 30px;

  li {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #666666;
    margin-top: 5px;

    &::before {
      content: "\2022";
      color: #666666;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}

.logrador-btnSection {
  background-color: #2C5999;
  width: 20%;
  border-radius: 10px;
  border: none;
  margin-top: 32px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #F7F7FC;
  height: 46px;

  @media only screen and (max-width: 1100px) {
    width: 40%;
  }
}

.logrador-sectionLocation {
  width: 25%;
  background-color: #EFF0F6;
  padding: 0px 50px;

  @media only screen and (max-width: 1100px) {
    width: 35%;
  }

  @media only screen and (max-width: 825px) {
    width: 100%;
  }
}

.logrador-location {
  margin-top: 107px;

  h6 {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: black;
  }
}

.logrador-eachSection {
  height: 50px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  width: 70%;

  p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: black;
  }

  &.active {
    background-color: #D9DCEE;

    p {
      font-weight: 700;
    }
  }
}
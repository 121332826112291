.tb {
  margin-top: 3rem;
}

.tb-hh {
  border-bottom: 1px solid #000;
}

.tb-r {
  border-bottom: 1px solid #D2D7DE;
}

.tb-r>* {
  padding-bottom: 1.35rem;
  padding-top: 1.35rem;
}

.tb-h>:nth-child(1) {
  font-size: 1rem;
  padding-right: 4rem;
  font-weight: bold;

  /* temporal fix */
  transform: translateX(25%);
}

.hh>* {
  justify-content: flex-start !important;
  transform: none !important;
}

/* .tb-b {} */

.tb-c>* {
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;

}

.tb-c-btn {
  transform: translateX(1rem);
}

.tb-btn-overrides {
  height: 2.25rem;

  border-radius: 5px;
  margin-left: initial;
  margin-right: initial;
}

.btn-sec {
  color: #2c5999;
  border-color: #2c5999;
}